import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Title,
  Legend,
  Plugin,
  ChartOptions,
  ChartData,
} from "chart.js";
import {
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import Footer from "../../components/Footer";
import PageTitle from "../../components/PageTitle";
import PageTitleWrapper from "../../components/PageTitleWrapper";
import { SiteModel, SiteState } from "../../types";
import { Fragment, useEffect, useState } from "react";
import { getSiteCompletes } from "../../api/overall";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, Tooltip, Legend, Title, ChartDataLabels);
ChartJS.defaults.set("plugins.datalabels", {
  color: "#FFFFFF",
});

const textCenter: Plugin<"doughnut", any> = {
  id: "textCenter",
  beforeDraw: (chart) => {
    let total = chart.data.datasets[0].data.reduce((p, c) => {
      return Number(p) + Number(c);
    }, 0);
    const { ctx } = chart;
    ctx.save();
    ctx.font = "bolder 20px sans-serif";
    ctx.fillStyle = "rgba(192, 30, 46, 1)";
    ctx.textBaseline = "middle";
    var txt = `${total}`;
    var stringWidth = ctx.measureText(txt).width;
    ctx.fillText(
      `${total}`,
      chart.getDatasetMeta(0).data[0].x - stringWidth / 2,
      chart.getDatasetMeta(0).data[0].y
    );
  },
};

const getOrCreateLegendList = (id: string) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer?.querySelector("ul");
  if (!listContainer) {
    listContainer = document.createElement("ul");
    listContainer.style.display = "flex";
    listContainer.style.flexDirection = "row";
    listContainer.style.margin = 0 + "";
    listContainer.style.padding = 0 + "";

    legendContainer?.appendChild(listContainer);
  }
  return listContainer;
};

const htmlLegendPlugin: Plugin<"doughnut", any> = {
  id: "htmlLegend",
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(options.containerID);
    while (ul.firstChild) {
      ul.firstChild.remove();
    }
    if (chart.options.plugins?.legend?.labels?.generateLabels) {
      const items = chart.options.plugins.legend.labels.generateLabels(chart);
      items.forEach((item) => {
        const li = document.createElement("li");
        li.style.alignItems = "center";
        li.style.cursor = "pointer";
        li.style.display = "flex";
        li.style.flexDirection = "row";
        li.style.marginLeft = "10px";

        li.onclick = () => {
          const canvases: HTMLCollectionOf<HTMLCanvasElement> = document
            .getElementById(options.type)!
            .getElementsByTagName("canvas");
          for (const canvas of canvases) {
            const chartItem = ChartJS.getChart(canvas);
            chartItem?.toggleDataVisibility(item.index!);
            chartItem?.update();
          }
          chart.update();
        };

        // Color box
        const boxSpan = document.createElement("span");
        boxSpan.style.background = item.fillStyle + "";
        boxSpan.style.borderColor = item.strokeStyle + "";
        boxSpan.style.borderWidth = item.lineWidth + "px";
        boxSpan.style.display = "inline-block";
        boxSpan.style.height = "20px";
        boxSpan.style.marginRight = "10px";
        boxSpan.style.width = "20px";

        // Text
        const textContainer = document.createElement("p");
        textContainer.style.color = item.fontColor + "";
        textContainer.style.margin = 0 + "";
        textContainer.style.padding = 0 + "";
        textContainer.style.textDecoration = item.hidden ? "line-through" : "";

        const text = document.createTextNode(item.text);
        textContainer.appendChild(text);

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      });
    }
  },
};

export default function Status() {
  const theme = useTheme();
  const [doughnuts, setDoughnuts] = useState<{ [key: string]: number[] }>({});
  const [companies, setCompanies] = useState<{ [key: string]: number[] }>({});

  useEffect(() => {
    async function load() {
      const response = await getSiteCompletes();
      if (response.ok) {
        const sites: SiteModel[] = await response.json();

        let companies: SiteState = Object();
        let regions: SiteState = Object();
        for (var i = 0; i < sites.length; i++) {
          if (sites[i].siteCompletePlanned != null) {
            const company = sites[i].inspectorCompany;
            const region = sites[i].region.toUpperCase().trim();
            if (regions[region] != null) {
              regions[region].push(sites[i]);
            } else {
              regions[region] = [sites[i]];
            }

            if (companies[company] != null) {
              companies[company].push(sites[i]);
            } else {
              companies[company] = [sites[i]];
            }
          }
        }

        let tempRegions: { [key: string]: number[] } = { Total: [0, 0, 0] };
        for (let index = 0; index < Object.keys(regions).length; index++) {
          const reg = Object.keys(regions)[index];
          let FAC = 0;
          let REJ = 0;
          let Others = 0;
          regions[reg].forEach((site: SiteModel) => {
            if (site.siteStatus === "FAC") {
              FAC += 1;
              tempRegions["Total"][0] += 1;
            } else if (site.siteStatus === "REJ") {
              REJ += 1;
              tempRegions["Total"][1] += 1;
            } else {
              Others += 1;
              tempRegions["Total"][2] += 1;
            }
          });
          tempRegions[reg] = [FAC, REJ, Others];
        }
        setDoughnuts(tempRegions);

        let tempCompanies: { [key: string]: number[] } = { Total: [0, 0, 0] };
        for (let index = 0; index < Object.keys(companies).length; index++) {
          const com = Object.keys(companies)[index];
          let FAC = 0;
          let REJ = 0;
          let Others = 0;
          companies[com].forEach((site: SiteModel) => {
            if (site.siteStatus === "FAC") {
              FAC += 1;
              tempCompanies["Total"][0] += 1;
            } else if (site.siteStatus === "REJ") {
              REJ += 1;
              tempCompanies["Total"][1] += 1;
            } else {
              Others += 1;
              tempCompanies["Total"][2] += 1;
            }
          });
          tempCompanies[com] = [FAC, REJ, Others];
        }
        setCompanies(tempCompanies);
      }
    }
    load();
    // document.querySelector('.legend')?.innerHTML = ChartJS.instances
  }, []);

  return (
    <>
      <PageTitleWrapper>
        <PageTitle heading="Site Acceptance - FAC Status" />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 1,
          }}
        >
          <Typography variant="h4">Site Acceptance by Region</Typography>
          <Grid
            container
            gap={1}
            spacing={1}
            id="region"
            columns={14}
            alignItems="center"
            justifyContent="center"
          >
            {Object.keys(doughnuts).map((item, index) => {
              const list: ChartData<"doughnut", number[], string> = {
                labels: ["FAC", "REJ", "Others"],
                datasets: [
                  {
                    label: "Region",
                    data: doughnuts[item],
                    backgroundColor: [
                      "RGBA(0,150,80,1)",
                      theme.colors.primary.main,
                      "RGBA(192,192,192,1)",
                    ],
                    borderColor: [
                      "RGBA(0,150,80,1)",
                      theme.colors.primary.main,
                      "RGBA(192,192,192,1)",
                    ],
                    borderWidth: 1,
                  },
                ],
              };

              const options: ChartOptions<"doughnut"> & any = {
                plugins: {
                  datalabels: {
                    display: function (context: any) {
                      return context.dataset.data[context.dataIndex] !== 0; 
                    },
                  },
                  htmlLegend: {
                    type: "region",
                    containerID: "legend-container-1",
                  },
                  title: {
                    display: false,
                  },
                  legend: {
                    display: false,
                    position: "bottom",
                  },
                },
                interaction: {
                  mode: "index" as const,
                  intersect: false,
                },
              };
              return (
                <Fragment key={index}>
                  <Grid
                    item
                    padding={0}
                    sm={index === 0 ? 3 : 2}
                    marginRight={index === 0 ? 2 : 0}
                  >
                    <Box>
                      <Typography
                        variant="h5"
                        textAlign="center"
                        marginBottom={1}
                      >
                        {item}
                      </Typography>
                      <Doughnut
                        data={list}
                        key={index}
                        options={options}
                        redraw={true}
                        plugins={
                          index === 0
                            ? [textCenter, htmlLegendPlugin]
                            : [textCenter]
                        }
                      />
                    </Box>
                  </Grid>
                  {index === 0 ? (
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ border: 1 }}
                    />
                  ) : null}
                </Fragment>
              );
            })}
          </Grid>
          <Box id="legend-container-1" marginTop={2} />
        </Paper>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h4">Site Acceptance by Company</Typography>
          <Grid
            container
            gap={1}
            spacing={1}
            id="company"
            columns={14}
            alignItems="center"
            justifyContent="center"
            alignSelf="center"
          >
            {Object.keys(companies).map((item, index) => {
              const list: ChartData<"doughnut", number[], string> = {
                labels: ["FAC", "REJ", "Others"],
                datasets: [
                  {
                    label: "company",
                    data: companies[item],
                    backgroundColor: [
                      "RGBA(0,150,80,1)",
                      theme.colors.primary.main,
                      "RGBA(192,192,192,1)",
                    ],
                    borderColor: [
                      "RGBA(0,150,80,1)",
                      theme.colors.primary.main,
                      "RGBA(192,192,192,1)",
                    ],
                    borderWidth: 1,
                  },
                ],
              };

              const options: ChartOptions<"doughnut"> & any = {
                plugins: {
                  datalabels: {
                    display: function (context: any) {
                      return context.dataset.data[context.dataIndex] !== 0; 
                    },
                  },
                  htmlLegend: {
                    type: "company",
                    containerID: "legend-container-2",
                  },
                  title: {
                    display: false,
                  },
                  legend: {
                    display: false,
                    position: "bottom",
                  },
                },
                interaction: {
                  mode: "index" as const,
                  intersect: false,
                },
              };

              return (
                <Fragment key={index}>
                  <Grid
                    item
                    sm={index === 0 ? 3 : 2}
                    marginRight={index === 0 ? 2 : 0}
                  >
                    <Typography
                      variant="h5"
                      textAlign="center"
                      marginBottom={1}
                    >
                      {item}
                    </Typography>
                    <Doughnut
                      data={list}
                      key={index}
                      options={options}
                      redraw={true}
                      plugins={
                        index === 0
                          ? [textCenter, htmlLegendPlugin]
                          : [textCenter]
                      }
                    />
                  </Grid>
                  {index === 0 ? (
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ border: 1 }}
                    />
                  ) : null}
                </Fragment>
              );
            })}
          </Grid>
          <Box id="legend-container-2" marginTop={2} />
        </Paper>
      </Container>
      <Footer />
    </>
  );
}
