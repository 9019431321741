import { AuthProvider } from "./contexts/AuthContext";
import ThemeProvider from "./theme/ThemeProvider";
import { useRoutes } from "react-router-dom";
import router from "./router";
import { CssBaseline } from "@mui/material";

function App() {
  const routes = useRoutes(router);
  return (
    <ThemeProvider>
      <CssBaseline />
      <AuthProvider>{routes}</AuthProvider>
    </ThemeProvider>
  );
}

export default App;
