import { lazy, Suspense } from "react";
import { Navigate, RouteObject } from "react-router-dom";
import BaseLayout from "./layouts/BaseLayout";
import RequiredAuth from "./layouts/SidebarLayout/RequiredAuth";
import SidebarLayout from "./layouts/SidebarLayout";
import SignIn from "./pages/SignIn";
import SuspenseLoader from "./components/SuspenseLoader";
import Status from "./pages/Status";

const Loader = (Component: any) => (props: JSX.IntrinsicAttributes) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const OverallProgress = Loader(lazy(() => import("./pages/OverallProgress")));
const ProgressVendor = Loader(
  lazy(() => import("./pages/ProgressVendor"))
);
const Status404 = Loader(lazy(() => import("./pages/Status404")));

const router: RouteObject[] = [
  {
    path: "/",
    element: <Navigate to="/dashboard" replace />,
  },
  {
    path: "/auth",
    element: <BaseLayout />,
    children: [{ path: "sign-in", element: <SignIn /> }],
  },
  {
    path: "/dashboard",
    element: (
      <RequiredAuth>
        <SidebarLayout />
      </RequiredAuth>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="/dashboard/status" replace />,
      },
      {
        path: "status",
        element: <Status />,
      },
      {
        path: "overall-progress",
        element: <OverallProgress />,
      },
      {
        path: "progress-vendor",
        element: <ProgressVendor />,
      },
      // {
      //   path: "vendor-performance",
      //   element: <VendorPerformance />,
      // },
      {
        path: "*",
        element: <Navigate to="/error/not-found" replace />,
      },
    ],
  },
  {
    path: "/error",
    element: <BaseLayout />,
    children: [
      {
        path: "not-found",
        element: <Status404 />,
      },
    ],
  },
];

export default router;
