type SignInProps = {
  username: string;
  password: string;
};

async function signIn({ username, password }: SignInProps) {
  let headers = new Headers();
  headers.set("Content-Type", "application/json");

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/auth/login`,
      {
        headers,
        method: "POST",
        body: JSON.stringify({ username: username, password: password }),
      }
    );
    if (response.status === 403) throw new Error("403 is unacceptable for me!");

    const resJson = await response.json();
    if (!response.ok) throw new Error(resJson.message);
    return resJson;
  } catch (error) {
    throw error;
  }
}

export { signIn };
