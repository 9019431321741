export function getSiteCompletes() {
  const username = process.env.REACT_APP_DATA_USER;
  const password = process.env.REACT_APP_DATA_PASS;
  let headers = new Headers();
  headers.set(
    "Authorization",
    `Basic ${window.btoa(username + ":" + password)}`
  );
  return fetch(`${process.env.REACT_APP_BASE_URL}/data/sites/complete`, {
    headers,
  });
}
