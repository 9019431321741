import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useAuth } from "../../contexts/AuthContext";
import { Outlet, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { styled } from "@mui/material/styles";

import { Alert, Card } from "@mui/material";
import { signIn as signInApi } from "../../api/auth";
import Logo from "../../components/LogoSign";

const MainContent = styled(Box)(
  ({ theme }) => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

export default function SignIn() {
  const { isAuthenticated, signIn } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = React.useState<String>();

  if (isAuthenticated) {
    navigate("/dashboard");
    return <Outlet />;
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const target = event.target as typeof event.target & {
      username: { value: string };
      password: { value: string };
    };
    const username = target.username.value;
    const password = target.password.value;
    if (username == null || password == null) {
      setError("The username/password is empty.");
      return;
    }

    try {
      const result = await signInApi({
        username: username,
        password: password,
      });
      await signIn(result.data, result.token);
      navigate("/dashboard");
    } catch (error) {
      setError("Wrong Credencials — Invalid username or password");
    }
  };

  return (
    <>
      <Helmet>
        <title>Sign In</title>
        <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />
      </Helmet>
      <MainContent>
        <Container maxWidth="md">
          <Box textAlign="center">
            <Logo />
          </Box>
          <Container maxWidth="sm" component="form" onSubmit={handleSubmit}>
            <Card sx={{ textAlign: "center", mt: 3, p: 4 }}>
              <Box textAlign="left">
                <Typography variant="h2" sx={{ my: 2 }}>
                  Sign in
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{ mb: 4 }}
                >
                  Fill in the fields below to sign into your account
                </Typography>
              </Box>
              {error ? <Alert severity="error">{error}</Alert> : null}
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Uesrname"
                name="username"
                autoComplete="username"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={() => handleSubmit}
              >
                Sign In
              </Button>
            </Card>
          </Container>
        </Container>
      </MainContent>
    </>
  );
}
