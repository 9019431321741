import { Box, styled } from "@mui/material";
import { Link } from "react-router-dom";
import mainLogo from "../LogoSign/ftab-192.png";

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`
);

const LogoSignWrapper = styled(Box)(
  () => `
        width: 52px;
        height: 38px;
        margin-top: 4px;
        transform: scale(.8);
`
);

const LogoTextWrapper = styled(Box)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
`
);

const LogoText = styled(Box)(
  ({ theme }) => `
        color: ${theme.palette.success.contrastText};
        font-size: ${theme.typography.pxToRem(16)};
        font-weight: ${theme.typography.fontWeightBold};
`
);

function Logo() {
  return (
    <LogoWrapper to="/dashboard">
      <LogoSignWrapper>
        <img src={mainLogo} alt="FTAP Tower Inspection" style={{ width: 48 }} />
      </LogoSignWrapper>
      <Box
        component="span"
        sx={{
          display: { xs: "none", sm: "inline-block" },
        }}
      >
        <LogoTextWrapper>
          <LogoText >FTAP Tower Inspection</LogoText>
        </LogoTextWrapper>
      </Box>
    </LogoWrapper>
  );
}

export default Logo;
