import { createContext, ReactNode, useContext, useState } from "react";

type AuthContextType = {
  user: any;
  signIn: (user: string, token: string) => void;
  signOut: (callback: VoidFunction) => void;
  isAuthenticated: boolean;
};

const AuthContext = createContext<AuthContextType>(null!);

type AuthProviderProps = {
  children?: ReactNode;
};
const AuthProvider = ({ children }: AuthProviderProps) => {
  const localUser = localStorage.getItem("user");
  const [user, setUser] = useState(localUser ? JSON.parse(localUser): null);

  let signIn = (newUser: any, token: string) => {
    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(newUser));
    setUser(newUser);
    return;
  };

  let signOut = (callback: VoidFunction) => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setUser(null);
    callback();
    return;
  };

  let isAuthenticated = localStorage.getItem("token") ? true : false;

  return (
    <AuthContext.Provider value={{ user, signIn, signOut, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  return useContext(AuthContext);
}

export { AuthContext, AuthProvider, useAuth };
